import { Heading } from '@troon/ui';
import TroonGolfLandingPages from './_components/layout';
import type { RouteDefinition } from '@solidjs/router';

export default function TroonGolfArizonaLandingPage() {
	return (
		<TroonGolfLandingPages title="Troon Golf Arizona" image="troon-golf-arizona.png" slugs={slugs} location="Arizona">
			<Heading as="h2">Experience the best golf in Arizona with Troon.</Heading>
			<p>
				Troon Golf courses in Arizona are as diverse as the Southwest itself, each woven into the rugged desert terrain
				with a unique vision and infused with the impeccable quality that is Troon Golf hallmark.
			</p>
		</TroonGolfLandingPages>
	);
}

const slugs: Record<string, string> = {
	'ak-chin-southern-dunes-golf-club': '602.334.4700',
	'boulders-resort-and-spa-scottsdale': '602.336.6436',
	'dove-valley-ranch-golf-club': '480.900.4853',
	'eagle-mountain-golf-club': '480.207.3906',
	'foothills-golf-club': '480.900.3721',
	'golf-club-of-estrella': '602.346.9612',
	'longbow-golf-club': '480.359.2879',
	'lookout-mountain-golf-club': '602.346.9342',
	'power-ranch-golf-club': '480.900.5935',
	'putting-world-scottsdale': '480.582.1221',
	'rancho-manana-golf-club': '480.488.0398',
	'sedona-golf-resort': '928.224.8820',
	'sterling-grove-golf-country-club': '888.399.4234',
	'sunridge-canyon-golf-club': '480.944.6177',
	'the-club-at-starr-pass': '520.276.1499',
	'the-phoenician': '602.346.9549',
	'the-westin-kierland-golf-club': '602.345.1939',
	'troon-north-golf-club': '602.357.0433',
	'tubac-golf-resort-and-spa': '520.413.1133',
};

export const route = { info: { hasHero: true } } satisfies RouteDefinition;
